import React, {useLayoutEffect, useState} from 'react';
import {Router} from "react-router-dom";
import Hyphenated from "react-hyphen";
import de from 'hyphenated-de';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {refreshFetch} from "./utils/RefreshFetch";
import {NavBar} from "./Components/Navigation/NavBar";
import {Footer} from "./Components/Navigation/Footer"
import ContentRouter from "./Components/ContentRouter";
import Notifications from "./Components/Overlays/Notifications";
import history from "./history";
import {HeartbeatNotification} from "./Components/Overlays/HeartbeatNotification";
import {IdleDetector} from "./Components/IdleDetector";

import "./Components/material-ui.scss"
import "./Components/Overlays/Hover/hover.scss"
import "./App.scss";

const autoLogoutMinutes = 30;

// Enables DayJS to parse formats like "H:mm".
dayjs.extend(customParseFormat);

export const App = () => {

    const [backupMode, setBackupMode] = useState(false);

    useLayoutEffect(() => {
        refreshFetch("/config/is-backup")
            .then(res => res.json())
            .then((res: boolean) => setBackupMode(res))
            .catch(error => console.error(error));
    }, []);

    return (
        <div className="App">
            <Router history={history}>
                <IdleDetector timeoutSeconds={autoLogoutMinutes * 60}/>
                <Hyphenated language={de}>
                    <Notifications/>
                    <HeartbeatNotification/>
                    <NavBar backupMode={backupMode}/>
                    <ContentRouter/>
                    <Footer/>
                </Hyphenated>
            </Router>
        </div>
    );
}
